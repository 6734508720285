/* Add application styles & imports to this file! */

@import '~bootstrap/dist/css/bootstrap.min.css';
/*@import '~@fullcalendar/timegrid/main.css';*/


/* You can add global styles to this file, and also import other style files */


/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


/*

    Some changes on 8/8/2021
    for mat tab color changes on focus

    body {
  font-family: Roboto, Arial, sans-serif;
  margin: 0;
}
*/




.basic-container {
  padding: 5px;
}

.version-info {
  font-size: 8pt;
  float: right;
}




/* label style */
.mat-tab-label {
  min-width: 14px !important;
  padding: 5px;
  background: #042E3A; /*#2D7C8E*/  
  color: white;
  font-size: 14px;
  font-weight: 500;
}
/* focus style */
.mat-tab-group.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus, .mat-tab-group.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus, .mat-tab-nav-bar.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus, .mat-tab-nav-bar.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus {
  background: #2D7C8E;
}
/* ink bar style */
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background: yellow;
  height: 10px;
}


/* 
.mat-tab-label{
  background: #e7e7e7;
  color:  black;
  min-width: 60px!important;
}
.mat-tab-group.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus, .mat-tab-group.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus, .mat-tab-nav-bar.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus, .mat-tab-nav-bar.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus{
  background: #e7e7e7;
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar{
   background: rgba(149, 165, 166,0.3);
    height: 35px;
    border-radius: 100px;
    margin-bottom: 5px;
}  
*/
/* Styles for the active tab label */
.mat-tab-label.mat-tab-label-active {
  min-width: 14px !important;

  padding: 5px;
  background-color: #2D7C8E; /*#042E3A;*/
  color: white;
  font-weight: 500;
  font-size: 14px;
}




